import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Image from './Image'
import cx from 'classnames'

const ImageDescriptionCarousel = ({ carousel }) => {
    const { sectionName, slide } = carousel
    const [currentSlide, updateSlide] = useState(0)

    return (
        <>
            {sectionName && (
                <h2
                    className={cx(
                        'hd-lg text-center capitalize mb-64 md:mb-80'
                    )}
                >
                    {sectionName}
                </h2>
            )}

            {slide.map(
                (
                    { title, description, featuredImage, leftAlignedImage },
                    i
                ) => {
                    return (
                        <div
                            key={`${title}-${i}`}
                            className={cx({
                                hidden: currentSlide !== i,
                                'transform animate-slide-left':
                                    currentSlide === i && leftAlignedImage,
                                'transform animate-slide-right':
                                    currentSlide === i && !leftAlignedImage,
                            })}
                        >
                            <div
                                className={cx(
                                    'md:flex md:items-center md:gap-80 lg:gap-120'
                                )}
                            >
                                <div
                                    className={cx({
                                        'transform animate-slide-right':
                                            currentSlide === i &&
                                            leftAlignedImage,
                                        'transform animate-slide-left':
                                            currentSlide === i &&
                                            !leftAlignedImage,
                                    })}
                                >
                                    {title && (
                                        <strong className={cx('hd-md')}>
                                            {title}
                                        </strong>
                                    )}
                                    {description && (
                                        <p
                                            className={cx(
                                                'text-21 leading-snug mt-20'
                                            )}
                                        >
                                            {description}
                                        </p>
                                    )}
                                </div>
                                {featuredImage && (
                                    <Image
                                        image={featuredImage}
                                        alt=""
                                        className={cx(
                                            'w-full md:w-1/2 md:max-w-600 md-d:mt-32 flex-shrink-0',
                                            {
                                                'order-first': leftAlignedImage,
                                            }
                                        )}
                                    />
                                )}
                            </div>
                        </div>
                    )
                }
            )}

            <div className={cx('flex space-x-8 justify-center mt-80')}>
                {slide.map((item, i) => (
                    <div key={`carousel-jump-${i}`}>
                        <button
                            className={cx(
                                'relative flex justify-center items-center text-14 font-semibold p-18 rounded-6',
                                'hover:bg-purple-700 focus:bg-purple-700 hover:text-white focus:text-white',
                                'transition ease-in duration-400',
                                {
                                    'bg-purple-700 text-white':
                                        i === currentSlide,
                                    'bg-gray-100 text-gray-500':
                                        i !== currentSlide,
                                }
                            )}
                            onClick={() => updateSlide(i)}
                        >
                            <span className={cx('absolute')}>{i + 1}</span>
                        </button>
                    </div>
                ))}
            </div>
        </>
    )
}

ImageDescriptionCarousel.propTypes = {
    carousel: PropTypes.object,
}

export default ImageDescriptionCarousel
