import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const Partnerships = ({
    partnershipsHeading,
    partnershipsText,
    partnershipsButtonUrl,
    partnershipsButtonLabel,
    page,
}) => {
    return (
        <section
            className={cx('bg-partnerships-md md:bg-partnerships text-white')}
        >
            <div
                className={cx(
                    'max-w-1264 mx-auto py-60 px-24 lg:py-100 text-center'
                )}
            >
                <h2 className={cx('hd-jb mb-20')}>{partnershipsHeading}</h2>
                <div className={cx('max-w-840 mx-auto mb-40 text-21')}>
                    <p>{partnershipsText}</p>
                </div>
                <a
                    href={partnershipsButtonUrl}
                    className={cx(
                        'inline-block text-16 font-bold leading-none bg-blue-500 px-20 py-16 rounded-6 duration-200 hover:bg-blue-700'
                    )}
                    data-track-gtm={`${page}|Partnerships|${partnershipsButtonLabel} Button`}
                >
                    {partnershipsButtonLabel}
                </a>
            </div>
        </section>
    )
}

Partnerships.propTypes = {
    partnershipsHeading: PropTypes.string,
    partnershipsText: PropTypes.string,
    partnershipsButtonLabel: PropTypes.string,
    partnershipsButtonUrl: PropTypes.string,
    page: PropTypes.string,
}

export default Partnerships
