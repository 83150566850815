import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import cx from 'classnames'

import Layout from '../components/Layout'
import Image from '../components/Image'
import ImageDescriptionCarousel from '../components/ImageDescriptionCarousel'
import Partnerships from '../components/Partnerships'
import smoothScroll from '../components/utils/smoothScroll'
import formatId from '../components/utils/formatId'

export const ProductsServicesPageTemplate = ({
    title,
    introduction,
    featuredImage,
    impactContent,
    highlightedItems,
    carousels,
    partnershipsHeading,
    partnershipsText,
    partnershipsButtonLabel,
    partnershipsButtonUrl,
}) => {
    return (
        <>
            <div
                className={cx(
                    'max-w-1264 mx-auto px-24 lg:px-32 my-60 lg:mb-120'
                )}
            >
                <section className={cx('md:flex')}>
                    <div className={cx('md:w-1/2')}>
                        <h1 className={cx('hd-jb text-purple-700')}>{title}</h1>
                        {introduction && (
                            <p className={cx('text-21 leading-snug mt-24')}>
                                {introduction}
                            </p>
                        )}
                        {featuredImage && (
                            <Image
                                image={featuredImage}
                                alt=""
                                className={cx('md:max-w-600 mt-32')}
                            />
                        )}
                    </div>

                    {impactContent && (
                        <dl
                            className={cx(
                                'md:w-1/2 md:ml-80 lg:ml-120 md-d:mt-32 space-y-48'
                            )}
                        >
                            {impactContent.map(({ title, description }) => (
                                <div key={title}>
                                    <dt className={cx('hd-md')}>{title}</dt>
                                    <dd
                                        className={cx(
                                            'text-21 leading-snug mt-20'
                                        )}
                                    >
                                        {description}
                                    </dd>
                                </div>
                            ))}
                        </dl>
                    )}
                </section>

                {highlightedItems && (
                    <section className={cx('mt-32 lg:mt-64 -mx-8 lg:-mx-24')}>
                        <div
                            className={cx(
                                'border-8 border-gray-100 rounded-24'
                            )}
                        >
                            <ul
                                className={cx(
                                    'flex flex-wrap pt-60 pb-20 text-center'
                                )}
                            >
                                {highlightedItems.map(
                                    (
                                        {
                                            title,
                                            icon,
                                            description,
                                            sectionName,
                                        },
                                        i
                                    ) => (
                                        <li
                                            className={cx(
                                                `w-full px-32 mb-60 md:w-1/${highlightedItems.length} group`
                                            )}
                                            key={`${title}-${i}`}
                                        >
                                            <button
                                                onClick={() =>
                                                    smoothScroll(
                                                        formatId(sectionName)
                                                    )
                                                }
                                            >
                                                {icon && (
                                                    <Image
                                                        image={icon}
                                                        alt=""
                                                        className={cx(
                                                            'w-90 mx-auto mb-24'
                                                        )}
                                                    />
                                                )}

                                                <div>
                                                    <strong
                                                        className={cx(
                                                            'inline-block hd-md mb-16 group-hover:shadow-underline duration-200'
                                                        )}
                                                    >
                                                        {title}
                                                    </strong>
                                                    {description && (
                                                        <p>{description}</p>
                                                    )}
                                                </div>
                                            </button>
                                        </li>
                                    )
                                )}
                            </ul>
                        </div>
                    </section>
                )}

                <div className={cx('space-y-80 mt-80')}>
                    {carousels.map((carousel, i) => (
                        <section
                            key={`${carousel.sectionName}-${i}`}
                            id={formatId(carousel.sectionName)}
                        >
                            <ImageDescriptionCarousel carousel={carousel} />
                        </section>
                    ))}
                </div>
            </div>
            {partnershipsHeading &&
                partnershipsText &&
                partnershipsButtonUrl &&
                partnershipsButtonLabel && (
                    <Partnerships
                        partnershipsHeading={partnershipsHeading}
                        partnershipsText={partnershipsText}
                        partnershipsButtonUrl={partnershipsButtonUrl}
                        partnershipsButtonLabel={partnershipsButtonLabel}
                        page="Products and Services"
                    />
                )}
        </>
    )
}

ProductsServicesPageTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    introduction: PropTypes.string,
    featuredImage: PropTypes.object,
    impactContent: PropTypes.array,
    highlightedItems: PropTypes.array,
    carousels: PropTypes.array,
    partnershipsHeading: PropTypes.string,
    partnershipsText: PropTypes.string,
    partnershipsButtonLabel: PropTypes.string,
    partnershipsButtonUrl: PropTypes.string,
}

const ProductsServicesPage = ({ data }) => {
    const { frontmatter } = data.markdownRemark

    return (
        <Layout
            title={frontmatter.title}
            description={frontmatter.introduction}
        >
            <ProductsServicesPageTemplate
                title={frontmatter.title}
                introduction={frontmatter.introduction}
                featuredImage={frontmatter.featuredImage}
                impactContent={frontmatter.impactContent}
                highlightedItems={frontmatter.highlightedItems}
                carousels={frontmatter.carousels}
                partnershipsHeading={frontmatter.partnershipsHeading}
                partnershipsText={frontmatter.partnershipsText}
                partnershipsButtonLabel={frontmatter.partnershipsButtonLabel}
                partnershipsButtonUrl={frontmatter.partnershipsButtonUrl}
            />
        </Layout>
    )
}

ProductsServicesPage.propTypes = {
    data: PropTypes.object.isRequired,
}

export default ProductsServicesPage

export const pageQuery = graphql`
    query ProductsServicesPageTemplate {
        markdownRemark(
            frontmatter: { templateKey: { eq: "products-services-page" } }
        ) {
            frontmatter {
                title
                introduction
                featuredImage {
                    childImageSharp {
                        fluid(maxWidth: 600, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                impactContent {
                    description
                    title
                }
                highlightedItems {
                    sectionName
                    title
                    description
                    icon {
                        extension
                        publicURL
                    }
                }
                carousels {
                    sectionName
                    slide {
                        title
                        description
                        featuredImage {
                            childImageSharp {
                                fluid(maxWidth: 600, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        leftAlignedImage
                    }
                }
                partnershipsHeading
                partnershipsText
                partnershipsButtonLabel
                partnershipsButtonUrl
            }
        }
    }
`
